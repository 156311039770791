import Box from '@weave-mui/box';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import i18n from '../../../../i18n';
import { isLongerThanAllowed } from '../../../../analysis/EmbodiedCarbon/AddECDefinition/helper';
import { Benchmark, ParametersModalType } from '../../../../types/metrics';
import {
  DESCRIPTION_INPUT_CHARACTERS_LIMIT,
  TABLE_VALUE_NUMBER_LIMIT,
  containsSpecialCharacters,
} from '../../../utils/dataPointsUtils';
import DataPointsInput from '../../shared/DataPointsInput/DataPointsInput';
import { InputTypeEnum } from '../../../types/input';
import {BENCHMARK_NAME_LIMIT, intOnlyExp} from "../../../utils/benchmarksUtils";
import { isNaN } from 'lodash';

interface BenchmarksBasicDetailsProps {
  selectedBenchmark: Benchmark;
  isReadOnly: boolean;
  modalType: ParametersModalType;
  setSelectedBenchmark: Dispatch<SetStateAction<Benchmark>>;
}

const BenchmarksBasicDetails: React.FC<BenchmarksBasicDetailsProps> = ({ selectedBenchmark, setSelectedBenchmark,modalType, isReadOnly}) => {
  const maxNumberAllowed: number = 14;

  const nameChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedBenchmark((prevState) => {
      return {
        ...prevState,
        name: value,
        displayName: value
      };
    })
  }, [setSelectedBenchmark]);

  const descriptionChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedBenchmark((prevState) => {
      return {
        ...prevState,
        description: value
      };
    })
  }, [setSelectedBenchmark]);


  const valueChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    if (!intOnlyExp.test(value)) {
      return;
    }

    if (value.length > maxNumberAllowed) {
      value = value.slice(0, maxNumberAllowed);
    }

    const integerValue = value === '' ? undefined : +value;
    setSelectedBenchmark((prevState: Benchmark) => {
      return {
        ...prevState,
        value: integerValue
      };
    });
  }, [setSelectedBenchmark]);

  // manipulate display values depending on the state of selectedBenchmark.value
  // - is 'benchmarkValue === NaN' because '-' is the only
  // value that passes the int check regex but upon conversion to number is NaN
  const displayValue = (): string | number => {
    if (!selectedBenchmark) {
      return '';
    }

    const benchmarkValue = selectedBenchmark.value;
    if (benchmarkValue === null || benchmarkValue === undefined) {
      return '';
    }

    if (isNaN(benchmarkValue)){
      return '-';
    }

    return benchmarkValue;
  }

  return (
    <Box sx={{ height: 'auto', width: '50%'}}>
      <Box data-testid='benchmarks-details-container' sx={
        {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '1.5rem'
        }
      }>
        <DataPointsInput
          name='name'
          placeholder={i18n.t('analysis.dataPoints.placeholders.name')}
          labelName={`${i18n.t('analysis.dataPoints.labels.name')} *`}
          disabled={isReadOnly}
          error={Boolean(isLongerThanAllowed(selectedBenchmark?.name, BENCHMARK_NAME_LIMIT) || containsSpecialCharacters(selectedBenchmark?.name))}
          inputType={InputTypeEnum.text}
          modalType={modalType}
          onChange={nameChangeHandler}
          value={
            selectedBenchmark?.displayName?.trim().length > 0
              ? selectedBenchmark?.displayName
              : selectedBenchmark?.name
          }
        />
        <DataPointsInput
          name='description'
          placeholder={`${i18n.t('analysis.dataPoints.benchmarks.descriptionPlaceholder')}`}
          labelName={`${i18n.t('analysis.dataPoints.labels.description')}`}
          disabled={isReadOnly}
          error={Boolean(isLongerThanAllowed(selectedBenchmark?.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT))}
          inputType={InputTypeEnum.textArea}
          modalType={modalType}
          onChange={descriptionChangeHandler}
          value={selectedBenchmark?.description}
        />
        <Box sx={{my: '2rem'}}>
          <DataPointsInput
            name='value'
            placeholder={i18n.t('analysis.dataPoints.placeholders.value')}
            labelName={`${i18n.t('analysis.dataPoints.labels.value')} *`}
            disabled={isReadOnly}
            error={Boolean(isLongerThanAllowed(`${selectedBenchmark?.value}`, TABLE_VALUE_NUMBER_LIMIT - 1))}
            inputType={InputTypeEnum.number}
            modalType={modalType}
            onChange={valueChangeHandler}
            value={displayValue()}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BenchmarksBasicDetails;
