import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
  FetchBaseQueryMeta,
  BaseQueryApi,
  retry,
} from '@reduxjs/toolkit/dist/query';
import { User } from '../../analysis/dashboard.models';
import authClient from '../../authClient';
import { RootState } from '../store';
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import {
  BaseQueryArg,
  BaseQueryExtraOptions,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AppErrorTypes } from '../../types/errorData';
import {DataPoints} from "../../types/metrics";

const addOauthAuthorizationHeader = async (headers: Headers) => {
  headers.set('Authorization', `Bearer ${await authClient.getAccessToken()}`);
  return headers;
};

export type QueryOptions = {
  errorOptions?: {
    notificationType: AppErrorTypes;
    messageOverride?: string;
  };
  analyticsOptions?: {
    eventType: string;
    eventName: string;
    recordFailureOperation?: boolean;
    parseResponseMeta?: <T>(responseData: T, params?: DataPoints) => Record<string, string>;
    parseRequestMeta?: <T>(requestData: T) => Record<string, string>;
    meta?: Record<string, string>;
  };
};

export const hasQueryOptions = (options: any): QueryOptions | null => {
  return (options as QueryOptions)?.errorOptions?.notificationType ? options : null;
};

export const hasAnalyticsQueryOptions = (options: any): QueryOptions | null => {
  return (options as QueryOptions)?.analyticsOptions?.eventType ? options : null;
};

export const handleQueryExtraOptions = async (
  meta: FetchBaseQueryMeta,
  extraOptions: QueryOptions,
  queryReturnValue: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
  getState: () => RootState,
): Promise<FetchBaseQueryMeta & QueryOptions> => {
  if (hasAnalyticsQueryOptions(extraOptions)) {
    const currentModelId = getState().applicationDataState?.currentModelId;
    extraOptions.analyticsOptions.meta = (currentModelId && { currentModelId }) || {};
    // Parse meta using the API response
    if (extraOptions.analyticsOptions?.parseResponseMeta && queryReturnValue.data) {
      extraOptions.analyticsOptions.meta = {
        ...extraOptions.analyticsOptions.parseResponseMeta(queryReturnValue.data),
        ...extraOptions.analyticsOptions.meta,
      };
    }

    // Parse meta using the API request
    if (extraOptions.analyticsOptions?.parseRequestMeta) {
      const requestData = await queryReturnValue.meta.request.json();
      extraOptions.analyticsOptions.meta = {
        ...extraOptions.analyticsOptions.parseRequestMeta(requestData),
        ...extraOptions.analyticsOptions.meta,
      };
    }
  }
  return { ...meta, ...(extraOptions ?? {}) };
};

// RTK retry options
const MAX_RETRIES = 3;
const RETRY_CODES = [429, 500, 502, 503, 504];
const retryConditionFunction = (
  error: FetchBaseQueryError,
  args: BaseQueryArg<BaseQueryFn>,
  extraArgs: {
    attempt: number;
    baseQueryApi: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn> & RetryOptions;
  },
): boolean => {
  return (
    typeof error.status === 'number' &&
    RETRY_CODES.includes(error.status as number) &&
    extraArgs.attempt <= MAX_RETRIES
  );
};
const retryOptions = { maxRetries: MAX_RETRIES, retryCondition: retryConditionFunction };

/**
 * RTK Query base query to call AIA DDX endpoints
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightDymanicUrlBaseQuery: (
  baseUrl: string,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = (
  baseUrl: string,
) =>
  retry(async (args, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl,
      prepareHeaders: addOauthAuthorizationHeader,
    })(args, api, extraOptions);
    result.meta = await handleQueryExtraOptions(
      result.meta,
      extraOptions,
      result,
      api.getState as () => RootState,
    );
    return result;
  }, retryOptions as any);

/**
 * RTK Query base query to call Apigee enabled endpoints
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightApigeeBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${(api.getState() as RootState).userState.user.apiApigeePath}/api`,
    prepareHeaders: addOauthAuthorizationHeader,
  })(args, api, extraOptions);
  result.meta = await handleQueryExtraOptions(
    result.meta,
    extraOptions,
    result,
    api.getState as () => RootState,
  );
  return result;
}, retryOptions as any);

/**
 * RTK Query base query to call Forge enabled endpoints
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightForgeBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${(api.getState() as RootState).userState.user.forgeBaseUrl}`,
  })(args, api, extraOptions);
  result.meta = { ...result.meta, ...(extraOptions ?? {}) };
  return result;
}, retryOptions as any);

/**
 * RTK Query base query to call Data service enabled endpoints
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightDataServiceBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${(api.getState() as RootState).userState.user.insightDataServiceUrl}/api`,
    prepareHeaders: addOauthAuthorizationHeader,
  })(args, api, extraOptions);
  result.meta = await handleQueryExtraOptions(
    result.meta,
    extraOptions,
    result,
    api.getState as () => RootState,
  );
  return result;
}, retryOptions as any);

/**
 * RTK Query base query to call Data service enabled endpoints
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightTaskServiceBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${(api.getState() as RootState).userState.user.insightTaskServiceUrl}/api`,
    prepareHeaders: addOauthAuthorizationHeader,
  })(args, api, extraOptions);
  result.meta = await handleQueryExtraOptions(
    result.meta,
    extraOptions,
    result,
    api.getState as () => RootState,
  );
  return result;
}, retryOptions as any);

/**\
 * RTK Query to call same domain endpoints i.e. token, entitlements etc
 * @param  {} args
 * @param  {} api
 * @param  {} extraOptions
 */
export const insightBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: '/auth',
    prepareHeaders: addOauthAuthorizationHeader,
  })(args, api, extraOptions);
  result.meta = { ...result.meta, ...(extraOptions ?? {}) };
  return result;
}, retryOptions as any);

export const extractUserState = (api: BaseQueryApi): User =>
  (api.getState() as RootState).userState?.user;
