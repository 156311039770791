import { createApi } from '@reduxjs/toolkit/query/react';
import { insightTaskServiceBaseQuery } from './base-queries';
import { POST } from './api-constants';

export type TaskInputs = {
  parameterId: string;
  value: number;
}
export type TaskRequest = {
  modelId: string;
  isBaseRun: boolean;
  inputs?: Array<TaskInputs>;
}

export const taskServiceApi = createApi({
  reducerPath: 'taskServiceApi',
  baseQuery: insightTaskServiceBaseQuery,
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    submitTask: builder.mutation<void, TaskRequest>({
      query: (body) => ({
        url: `/v1/task/:energy-simulation`,
        method: POST,
        body,
      }),
    }),
  }),
});

export const { useSubmitTaskMutation } = taskServiceApi;
